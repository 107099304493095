<template>
	<div class="puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.berthblock.berthname')" :value="getBerthName"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.berthblock.name')" :value="getBlockname"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'berthblock-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'berthblock'
		};
	},
	computed: {
		getBerthName() {
			return this.model && this.model.berthname ? this.model.berthname : '';
		},
		getBlockname() {
			return this.model && this.model.name ? this.model.name : '';
		}
	}
};
</script>
